import React from 'react';
import { I18nextProvider } from 'react-i18next';
import App from 'next/app';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import i18n from '../utils/i18n';
import getUrlParameter from '../helpers/getUrlParameter';
import config from '../config';
import Layout from '../components/Layout';
import AuthStore from '../store/AuthStore';
import ErrorBoundary from '../components/ErrorBoundary';
import '../styles/index.scss';

class AuthApp extends App {
  state = {
    returnTo: getUrlParameter('return_to') || config.returnToDefault.encodedUrl,
    predefinedPhone: getUrlParameter('phone') || null,
    backTo: getUrlParameter('back_to') || null
  };

  render() {
    const { state, props } = this;
    const { Component, pageProps, router: { route } } = props;

    if (route === '/404') {
      return (
        <I18nextProvider i18n={i18n}>
          <Component {...pageProps} />
        </I18nextProvider>
      );
    }

    return (
      <I18nextProvider i18n={i18n}>
        <Head>
          <title>Authentication by phone number</title>
        </Head>
        <AuthStore {...state}>
          <Layout>
            <ErrorBoundary>
              <Component {...pageProps} />
            </ErrorBoundary>
          </Layout>
        </AuthStore>
      </I18nextProvider>
    );
  }
}

export default dynamic(() => Promise.resolve(AuthApp), {
  ssr: false
});
